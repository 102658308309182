@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200&display=swap");

*,
::before,
::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	font-size: 62.5%;
}

@media only screen and (max-width: 75em) {
	html {
		font-size: 56.25%;
	}
}

@media only screen and (max-width: 56.25em) {
	html {
		font-size: 50%;
	}
}

@media only screen and (min-width: 112.5em) {
	html {
		font-size: 75%;
	}
}

body {
	font-family: "Plus Jakarta Sans", sans-serif !important;
	padding: 0;
}
home-header,
main,
footer {
	font-family: "Plus Jakarta Sans", sans-serif;
}

a {
	text-decoration: none;
	color: inherit;
	cursor: pointer;
}
a:hover {
	color: inherit;
}

p {
	margin-bottom: 0;
}

li {
	list-style-type: none;
}

button {
	border: none;
	outline: none;
	background-color: inherit;
	color: inherit;
	cursor: pointer;
	line-height: normal;
}

.heading-primary {
	display: inline-block;
	width: 61%;
	margin-bottom: 3rem;
}

@media only screen and (max-width: 56.25em) {
	.heading-primary {
		width: 80%;
	}
}

@media only screen and (max-width: 530px) {
	.heading-primary {
		width: 100%;
	}
}

.heading-primary h1 {
	font-size: 6rem;
	font-weight: 800;
	line-height: 81.9px;
}

.heading-secondary {
	display: inline-block;
	width: 50%;
	margin-bottom: 3rem;
}

@media only screen and (max-width: 530px) {
	.heading-secondary {
		width: 90%;
	}
}

.heading-secondary p {
	font-size: 1.8rem;
	line-height: 26.91px;
	font-weight: 500;
}

.text h3 {
	font-size: 1.7rem;
	color: #c51c2f;
	font-weight: 700;
	text-transform: uppercase;
}

.heading-primary--2 h1 {
	font-size: 4.5rem;
	line-height: 60px;
	color: #111;
}

.heading-secondary--2 p {
	font-size: 1.5rem;
	color: #484848;
}

.text-main h3 {
	color: #111;
	font-size: 2.2rem;
}

.text-main-2 h3 {
	font-size: 1.7rem;
}

.capitalise {
	text-transform: capitalize;
}

.def-line-height {
	line-height: 27px;
}

@media only screen and (max-width: 56.25em) {
	.def-line-height {
		line-height: 23px;
	}
}

.color-peach {
	color: #ff8c5e !important;
}

@media only screen and (max-width: 428px) {
	.heading-primary h1 {
		font-size: 5rem;
		line-height: 61.9px;
	}

	.heading-primary--2 h1 {
		line-height: 50px;
	}
}

.flex {
	display: flex;
}

@media only screen and (max-width: 56.25em) {
	.flex {
		flex-direction: column;
	}
}

.flex-row {
	flex-direction: row;
}

.flex-column {
	flex-direction: column;
}

.u-align-center {
	align-items: center;
}

.u-justify-content-sb {
	justify-content: space-between;
}

.cbt-container {
	width: 75%;
	margin: 0 auto;
}

@media only screen and (max-width: 530px) {
	.cbt-container {
		width: 83%;
	}
}

.display-none {
	display: none;
}

.margin-bottom-1 {
	margin-bottom: 1rem;
}

.margin-bottom-2 {
	margin-bottom: 2rem;
}

.margin-bottom-3 {
	margin-bottom: 3rem;
}

.margin-bottom-4 {
	margin-bottom: 4rem;
}

.margin-bottom-5 {
	margin-bottom: 5rem;
}

.margin-bottom-7 {
	margin-bottom: 7rem;
}

.margin-bottom-8 {
	margin-bottom: 8rem;
}

.margin-left-1 {
	margin-left: 2.3rem;
}

.margin-right {
	margin-right: 3rem;
}

.margin-top {
	margin-top: 5rem;
}

.margin-top-2 {
	margin-top: 10rem;
}

.margin-top-3 {
	margin-top: 7rem;
}

.cta {
	gap: 2rem;
}

.cta button {
	font-size: 1.7rem;
}

.card:hover h3 {
	color: #c51c2f;
	cursor: pointer;
	transition: color 0.3s ease-in-out;
}

.cta .button--2 button {
	background-color: #fff;
	display: inline-block;
	color: #c51c2f;
	border-radius: 5px;
	padding: 1rem 2rem;
	font-size: 1.7rem;
}

.cards {
	gap: 4rem;
}

.cards .card {
	background-color: #fafafb;
	width: 50%;
	height: 47rem;
	border-radius: 30px;
	padding-top: 5rem;
	overflow: hidden;
}

@media only screen and (max-width: 56.25em) {
	.cards .card {
		width: 100%;
	}
}

.cards .card .card-content {
	width: 83%;
	margin: 0 auto;
}

.cards .card .card-image {
	text-align: center;
	cursor: pointer;
	transition: transform 0.3s ease-in-out;
}

.cards .card .card-image img {
	width: 100%;
}

.cards .card .card-image:hover {
	transform: scale(1.15) translateY(-1rem);
}

@media only screen and (max-width: 450px) {
	.card_image--1 {
		margin-top: 10rem;
	}

	.card_image--3 {
		margin-top: 11rem;
	}

	.card_image--4 {
		margin-top: 8rem;
	}
}

.faq {
	padding: 5rem 2rem;
	width: 85%;
}

details {
	border-bottom: 1px solid #e7e5ea;
	margin-bottom: 2.5rem;
	transition: all 0.5s ease-in-out;
}

@keyframes open {
	0% {
		opacity: 0;
		transform: translateY(-1);
	}

	100% {
		opacity: 1;
		transform: translateY(1);
	}
}

details summary::-webkit-details-marker {
	display: none;
}

details summary {
	width: 100%;
	padding: 1.7rem 0;
	margin-bottom: 1rem;
	position: relative;
	cursor: pointer;
	font-size: 2rem;
	font-weight: 500;
	list-style: none;
	transition: all 0.5s linear;
}

@media only screen and (max-width: 530px) {
	details summary {
		font-size: 1.7rem;
	}
}

details summary:after {
	content: "";
	background-image: url(../../assets/images/arrowRight.png);
	position: absolute;
	right: 0;
	transform-origin: center;
	transition: 200ms linear;
}

details[open] summary i {
	transform: rotate(90deg);
	transition: all 0.5s ease-in-out;
}

details[open] {
	transition: all 0.5s ease-in-out;
}

details summary {
	outline: 0;
}

@media only screen and (max-width: 768px) {
	.faq {
		width: 100%;
	}
}

.footer-info {
	flex-basis: 35%;
}

.footer-contact {
	gap: 3rem;
}

@media only screen and (max-width: 530px) {
	.footer-contact {
		flex-direction: column;
		align-items: baseline;
	}
}

.gap {
	gap: 1rem;
}

@media only screen and (max-width: 530px) {
	.contact {
		flex-direction: row;
	}
}

.contact p {
	font-size: 1.6rem;
}

.line {
	display: block;
	background-color: #e7e5ea;
	width: 100%;
	height: 2px;
}

.footer-links ul {
	gap: 3rem;
}

.footer-links ul li {
	color: #484848;
	font-size: 1.7rem;
}

.social-links {
	cursor: pointer;
}

.social-links i {
	color: #c51c2f;
	font-size: 2.7rem;
}

.home-header {
	height: 813px;
	width: 100%;
	background-color: #c51c2f;
	background-repeat: no-repeat;
	background-position: bottom;
	background-size: contain;
	color: #fff;
	position: relative;
}

@media only screen and (max-width: 56.25em) {
	.home-header {
		height: 720px;
	}
}

.home-header .clip-bg {
	background-color: #a50013;
	width: 100%;
	height: 40rem;
	position: absolute;
	bottom: 0;
	-webkit-clip-path: polygon(0 71%, 100% 0, 100% 100%, 0% 100%);
	clip-path: polygon(0 71%, 100% 0, 100% 100%, 0% 100%);
}

.home-header .section_hero {
	position: absolute;
	top: 65%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	text-align: center;
}

@media only screen and (max-width: 56.25em) {
	.home-header .section_hero {
		top: 63%;
	}
}

@media only screen and (max-width: 530px) {
	.home-header .section_hero {
		top: 50%;
	}
}

.home-header .hero-image {
	margin: 7rem;
}

@media only screen and (max-width: 530px) {
	.home-header .hero-image {
		display: none;
	}
}

@media only screen and (max-width: 56.25em) {
	.home-header .hero-image img {
		width: -webkit-fill-available;
	}
}

@media only screen and (max-width: 1024px) {
	.hero-image img {
		width: -webkit-fill-available;
	}
}

@media only screen and (max-width: 768px) {
	.home-header {
		height: 675px;
	}

	.section_hero {
		top: 65%;
	}
}

@media only screen and (max-width: 530px) {
	.home-header {
		height: 100vh;
	}
}

.menu_bar {
	position: fixed;
	width: 100%;
	padding: 1.5rem 0;
	background-color: #c51c2f;
	z-index: 1000;
}

.menu_bar .navigation ul {
	gap: 3rem;
}

.menu_bar .navigation li a {
	font-size: 1.7rem;
	font-weight: 400;
}

@media only screen and (max-width: 56.25em) {
	.menu_bar {
		display: none;
	}
}

.mobile {
	background-color: #c51c2f;
}

.mobile .icon {
	font-size: 3.5rem;
	cursor: pointer;
}

.mobile-nav {
	text-align: right;
	padding: 5rem 0;
	transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 56.25em) {
	.mobile {
		display: block;
	}
}

.open {
	display: block;
	transition: all 0.3s ease-in-out;
}

.close {
	display: none;
	transition: all 0.3s ease-in-out;
}

.section_about {
	margin-top: 10rem;
	padding: 5rem 0;
}

.section_features {
	padding: 10rem 0;
	background-color: #fffaf8;
}

.section-faq {
	padding: 10rem 0;
	margin-top: 3rem;
}

.features {
	gap: 5rem;
}

.content {
	width: 50%;
	margin: 0 auto;
	margin-bottom: 7rem;
	text-align: center;
}

@media only screen and (max-width: 56.25em) {
	.content {
		width: 50%;
	}
}

@media only screen and (max-width: 530px) {
	.content {
		width: 90%;
	}
}

.about_card {
	margin: 0 auto;
}

.faq_content {
	width: 55%;
}

@media only screen and (max-width: 768px) {
	.content {
		width: 60%;
	}
}

@media only screen and (max-width: 530px) {
	.content {
		width: 90%;
	}

	.faq_content {
		width: 90%;
	}

	.footer-contact {
		flex-direction: column !important;
	}
}

/*# sourceMappingURL=main.css.map */
