.header {
	font-family: "Inter";
	font-style: normal;
	font-weight: 700;
	font-size: 2.4rem;
	line-height: 120%;
	margin-bottom: 0px;
	align-self: flex-start;
	color: #c51c2f !important;
	height: auto !important;
	background-color: initial !important;
	margin-top: 30px;
}

.subheader {
	font-family: "Inter";
	font-style: normal;
	font-size: 1.8rem;
	line-height: 120%;
	margin-bottom: 40px;
	align-self: flex-start;
	color: #c51c2f !important;
	height: auto !important;
	background-color: initial !important;
	margin-top: 5px;
}

.subheader span {
	font-weight: 700;
}
