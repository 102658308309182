.form-area {
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	margin: 70px auto;
	padding: 30px 30px;
	max-width: 450px;
}

.login-input {
	border-radius: 5px;
	width: 100%;
	padding: 10px 15px;
	border: 1px solid #a7a3a3;
	box-sizing: border-box;
	color: black;
	font-size: 1.4rem;
}

.form-label {
	font-family: "Inter";
	font-style: normal;
	font-weight: 600;
	font-size: 1.6rem;
	line-height: 24px;
	color: #8a8383;
}

.header {
	font-family: "Inter";
	font-style: normal;
	font-weight: 700;
	font-size: 2.4rem;
	line-height: 120%;
	margin-bottom: 40px;
	align-self: flex-start;
	color: #c51c2f !important;
	height: auto !important;
	background-color: initial !important;
}

.submit-btn {
	width: 100px;
	height: 48px;
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 1.6rem;
	line-height: 28px;
	border-radius: 8px;
	border: none;
	color: white;
	background: #c51c2f;
	margin-left: auto;
}
