@import url(../../assets/theme.css);

body {
	background: var(--background);
	padding: 50px;
}

.details-container {
	margin-top: 70px;
}

.results-container {
	background: var(--white);
	border-radius: 20px;
	margin-top: 30px;
	min-height: 200px;
	padding: 40px 60px 60px 60px;
}

.header-text {
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 1.6rem;
	line-height: 29px;
	letter-spacing: 3px;
	color: var(--primary);
}

.header-content-text {
	font-family: Inter;
	font-style: normal;
	font-weight: 700;
	font-size: 1.8rem;
	line-height: 29px;
	color: var(--black);
}

.header-text-sub {
	margin-left: 10px;
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	font-size: 1.8rem;
	line-height: 29px;
	color: var(--black);
}

.start {
	width: 150px;
	height: 48px;
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 1.6rem;
	line-height: 28px;
	border-radius: 8px;
	margin: 30px 0;
	float: right;
	border: none;
	color: var(--white);
	background: var(--primary);
}

.summary-row {
	padding: 10px 0;
	margin: 10px 0;
}

.bg-secondary {
	background-color: var(--secondary) !important;
}

.instruction p {
	margin-left: 10px;
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	font-size: 1.8rem;
	line-height: 29px;
	color: var(--black);
}

.qa-container {
	background: var(--white);
	border-radius: 20px;
	margin-bottom: 40px;
	padding: 40px 60px;
	min-height: 200px;
}

.qa-number {
	font-family: Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 1.6rem;
	line-height: 29px;
	letter-spacing: 3px;
	color: rgba(0, 0, 0, 0.63);
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.qa-text {
	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 2.4rem;
	line-height: 29px;
	margin: 10px 0;
	color: (--black);
}

.qa-text * {
	max-width: 100%;
	overflow-wrap: break-word;
	hyphens: auto;
	word-break: break-word;
}

.qa-option-container {
	margin: 30px 0 0;
}

.option {
	margin-bottom: 20px;
	overflow-wrap: break-word;
	hyphens: auto;
	word-break: break-word;
	font-size: 1.4rem;
	font-weight: 500;
}

.option-label {
	font-weight: bold;
	color: var(--primary-color);
	margin: 0;
	font-size: 1.4rem;
	font-weight: 500;
}

.qa-correct {
	background-color: green;
	color: white;
	font-weight: bold;
	letter-spacing: 1px;
	padding: 5px 15px;
	border-radius: 7px;
}

.qa-incorrect {
	background-color: red;
	color: white;
	font-weight: bold;
	letter-spacing: 1px;
	padding: 5px 15px;
	border-radius: 7px;
}

.correct {
	color: green !important;
}

.selected-tick {
	font-size: 2rem;
}

.qa-selected {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 20px;
	margin-bottom: 0;
	width: 120px;
	padding: 7px 15px;
	background-color: rgb(171, 171, 171, 0.7);
	border-radius: 20px;
}

.bold-text {
	font-weight: 700;
}

.red-text {
	color: var(--primary);
}

.result-text {
	font-size: 1.4rem;
	font-weight: 600;
}

@media screen and (max-width: 768px) {
	.qa-container {
		padding: 20px;
	}

	.instruction p {
		margin-left: 0px !important;
	}
}
