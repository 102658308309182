:root {
	--primary-color: #c51c2f;
	--secondary-color: #ffe0e4;
	--tertiary-color: #f6f0df;
	--border-radius: 5px;
	--white: #ffffff;
}

body {
	font-size: 1.6rem;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.btn-loader-red {
	width: 25px;
	height: 25px;
	display: inline-block;
	padding: 0px;
	border-radius: 100%;
	border: 2px solid;
	border-top-color: rgba(225, 0, 0, 1);
	border-bottom-color: rgba(225, 0, 0, 0.15);
	border-left-color: rgba(225, 0, 0, 1);
	border-right-color: rgba(225, 0, 0, 0.15);
	-webkit-animation: btn-loader4 0.8s linear infinite;
	animation: btn-loader 0.8s linear infinite;
}

.btn-loader {
	width: 25px;
	height: 25px;
	display: inline-block;
	padding: 0px;
	border-radius: 100%;
	border: 2px solid;
	border-top-color: rgba(225, 255, 255, 1);
	border-bottom-color: rgba(225, 0, 0, 0.15);
	border-left-color: rgba(225, 255, 255, 1);
	border-right-color: rgba(225, 0, 0, 0.15);
	-webkit-animation: btn-loader4 0.8s linear infinite;
	animation: btn-loader 0.8s linear infinite;
}

@keyframes btn-loader {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

@-webkit-keyframes btn-loader {
	from {
		-webkit-transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
	}
}

.logo-image {
	width: 150px;
	object-fit: contain;
}

.has-pwd-icon {
	position: relative;
}

.has-pwd-icon input[type="“password”"] {
	padding-right: 35px;
}

.has-pwd-icon .view-pwd {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	margin: 0 15px;
	color: #8a8383;
	cursor: pointer;
}

.add-student-upload {
	height: 140px;
	width: 140px;
	background-color: white;
	padding: 1px;
}

.add-student-upload-container {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 50px;
}

.thumbs-container {
	width: 100%;
	height: 100%;
	border-radius: 10px;
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px solid var(--primary-color);
	overflow: hidden;
}

.thumbs-container:hover .thumbs-info {
	opacity: 0;
}

.thumbs-image {
	height: 100%;
	width: 100%;
	object-fit: contain;
}

.thumbs-info {
	position: absolute;
	bottom: 15px;
	left: 0px;
	right: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--primary-color);
	color: white;
	height: 30px;
	font-size: 1.2rem;
	/* opacity: 0.5; */
}

.form-container {
	width: 450px;
	margin: 60px auto;
}

.form-label {
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	font-size: 1.6rem;
	line-height: 24px;
	text-align: center;
	margin-bottom: 50px;
	color: grey;
	text-align: left;
	margin-bottom: 10px;
}

.login-btn {
	width: 100%;
	height: 48px;
	border-radius: 10px;
	background-color: var(--primary-color);
	color: #ffffff;
	border: none;
	font-family: Inter;
	font-style: normal;
	font-weight: bold;
	font-size: 1.6rem;
	line-height: 19px;
	margin-top: 30px;
}

.login-input {
	border-radius: 5px;
	width: 100%;
	padding: 10px 15px;
	border: 1px solid #a7a3a3;
	box-sizing: border-box;
	color: black;
}

.form-control {
	display: block;
	width: 100%;
	padding: 3px 10px;
	font-size: 1.4rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	height: 42px;
}

.exctr-select-input select {
	color: #8a8383;
	appearance: none;
}

.exctr-select-input.large select {
	height: 48px;
}

.exctr-select-input {
	position: relative;
}

.exctr-select-input:after {
	content: url("./assets/images/arrow-down-s-line.svg");
	position: absolute;
	font-family: "FontAwesome";
	top: 8px;
	right: 10px;
	font-size: 0.7rem;
	color: #8a8383;
}

.pri-action-btn,
.pri-action-btn:link,
.pri-action-btn:visited {
	text-decoration: none;
	text-transform: capitalize;
	display: inline-block;
	align-items: center;
}

.pri-action-btn {
	background-color: var(--primary-color) !important;
	border-radius: var(--border-radius) !important;
	padding: 8px 25px;
	color: var(--white) !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 24px !important;
	text-align: center;
	border: 2px solid var(--primary-color) !important;
}

.pri-action-btn:hover {
	color: var(--primary-color) !important;
	border: 2px solid var(--primary-color) !important;
	background-color: transparent !important;
	transition: ease-in-out 300ms;
}

.signup-settings-header {
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	color: var(--primary-color);
	margin-top: 30px;
}

.my-container {
	max-width: 1400px;
	padding: 10px 20px;
	margin-inline: auto;
}

.register-form {
	width: 95%;
	max-width: 700px !important;
	font-size: 1.6rem;
}

.register-form label {
	color: black;
	font-family: 500;
}

@media screen and (max-width: 475px) {
	.hide-small {
		display: none;
	}

	.my-container {
		font-size: 60% !important;
	}
}

@media screen and (max-width: 768px) {
	.hide-mid {
		display: none;
	}

	.my-container {
		font-size: 62.25% !important;
	}
}
